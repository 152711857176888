import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { mobiscroll, MbscSelectOptions } from '@mobiscroll/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastController, ModalController, NavParams } from '@ionic/angular';
import { PassportComponent } from 'src/app/components/passport/passport.component';
import { VisasComponent } from 'src/app/components/visas/visas.component';

@Component({
    selector: 'app-passenger',
    templateUrl: './passenger.component.html',
    styleUrls: ['./passenger.component.scss'],
})
export class PassengerComponent implements OnInit {

    account: any;
    passenger: any;
    loaded: boolean = false;
    profileForm: FormGroup;

    constructor(
        public api: ApiService,
        public auth: AuthService,
        public formBuilder: FormBuilder,
        public toastController: ToastController,
        public modalController: ModalController,
        public navParams: NavParams
    ) {
        this.passenger = navParams.get('passenger');
    }

    ngOnInit() {
        this.loaded = true;
    }


    dismiss() {
        this.modalController.dismiss();
    }

    async goToPassport() {

        const modal = await this.modalController.create({
            component: PassportComponent,
            componentProps: {
                'account': this.passenger.passenger,
            }
        });

        modal.present();

    }

    async goToVisas() {

        const modal = await this.modalController.create({
            component: VisasComponent,
            componentProps: {
                'account': this.passenger.passenger,
            }
        });

        modal.present();

    }

}
