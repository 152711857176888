import { Component, OnInit, NgZone } from '@angular/core';
import { ModalController, NavParams, AlertController, LoadingController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import algoliasearch from 'algoliasearch';
import { algoliaConfig } from 'src/environments/environment'
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';


const client = algoliasearch(algoliaConfig.appId, algoliaConfig.apiKey);
const index = client.initIndex('airports');

@Component({
    selector: 'app-airports',
    templateUrl: './airports.component.html',
    styleUrls: ['./airports.component.scss'],
})
export class AirportsComponent implements OnInit {

    type: any;
    airports: any;
    airports_full: any;

    searching: any = false;
    searchTerm: string = '';
    searchControl: FormControl;
    empty = true;
    loading: boolean = false;
    frequents: any = [];

    constructor(
        public navParams: NavParams,
        public ModalController: ModalController,
        public alertController: AlertController,
        private zone: NgZone,
        public loadingCtrl: LoadingController,
        public api: ApiService,
        public auth: AuthService
    ) {
        this.searchControl = new FormControl();
        this.type = navParams.get('type');


    }

    ngOnInit() {
    }

    setSearch(e) {
        this.loading = false;
        let query = e.detail.value;

        if (query == '') {
            this.empty = true;
            this.loading = false;
        } else {
            this.empty = false;
            this.airports = [];
            index.search(query)
                .then(({ hits }) => {
                    hits.forEach(element => {
                        this.airports.push(element);
                    });
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    dismiss() {
        this.ModalController.dismiss();
    }

    forceUpdate() {
        this.zone.run(() => {
            console.log('force update the screen');
        });
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'dots',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    selectAirport(airport) {
        delete airport._highlightResult;

        this.loader().then(loader => {
            loader.present().then(() => {
                loader.dismiss();
                this.ModalController.dismiss(
                    airport
                );
            });
        });

    };

    selectFrequent(airport) {
        this.ModalController.dismiss(
            airport
        );
    };
};
