import { MbscModule } from '@mobiscroll/angular';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule, FirebaseApp } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { firebaseConfig } from './../environments/environment'

import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AddOperatorComponent } from './components/add-operator/add-operator.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddAircraftComponent } from './components/add-aircraft/add-aircraft.component';
import { ImageUploadModule } from "angular2-image-upload";
import { AirportsComponent } from './components/airports/airports.component';
import { RequestComponent } from './components/request/request.component';
import { AddBidComponent } from './components/add-bid/add-bid.component';
import { AircraftsComponent } from './components/aircrafts/aircrafts.component';
import { PassportComponent } from './components/passport/passport.component';
import { PassengerComponent } from './components/passenger/passenger.component';
import { VisasComponent } from './components/visas/visas.component';
import { DealComponent } from './components/deal/deal.component';
import { AuthGuard } from './guards/auth.guard';
import { ChatComponent } from './components/chat/chat.component';
import { BidsComponent } from './components/bids/bids.component';
import { AddDealComponent } from './components/add-deal/add-deal.component';
import { AircraftComponent } from './components/aircraft/aircraft.component';
import { AddUserOperatorComponent } from './components/add-user-operator/add-user-operator.component';
import { AddNewComponent } from './components/add-new/add-new.component';
import { AddAdviceComponent } from './components/add-advice/add-advice.component';
import { RadarComponent } from './components/radar/radar.component';
import { CharterComponent } from './components/charter/charter.component';
import {AddUserComponent} from './components/add-user/add-user.component'

@NgModule({
    declarations: [
        AppComponent,
        AddOperatorComponent,
        AddAircraftComponent,
        AirportsComponent,
        RequestComponent,
        AddBidComponent,
        AircraftsComponent,
        PassengerComponent,
        PassportComponent,
        VisasComponent,
        DealComponent,
        ChatComponent,
        BidsComponent,
        AddDealComponent,
        AircraftComponent,
        AddUserOperatorComponent,
        AddNewComponent,
        AddAdviceComponent,
        RadarComponent,
        CharterComponent,
        AddUserComponent
    ],
    entryComponents: [
        AddOperatorComponent,
        AddAircraftComponent,
        AirportsComponent,
        RequestComponent,
        AddBidComponent,
        AircraftsComponent,
        PassengerComponent,
        PassportComponent,
        VisasComponent,
        DealComponent,
        ChatComponent,
        BidsComponent,
        AddDealComponent,
        AircraftComponent,
        AddUserOperatorComponent,
        AddNewComponent,
        AddAdviceComponent,
        CharterComponent,
        RadarComponent,
        AddUserComponent
    ],
    imports: [
        MbscModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        ImageUploadModule.forRoot(),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        AuthGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
