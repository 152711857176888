import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController, NavParams, LoadingController, AlertController, ToastController } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { AircraftsComponent } from '../aircrafts/aircrafts.component';
import { AirportsComponent } from '../airports/airports.component';

@Component({
    selector: 'app-add-bid',
    templateUrl: './add-bid.component.html',
    styleUrls: ['./add-bid.component.scss'],
})
export class AddBidComponent implements OnInit {

    request: any;
    operators: any;
    operator: any;
    aircrafts: any;
    aircraft: any;
    lastFlightInfo: any;
    price: any;
    preliminary: any = false;
    deals: any = [];
    custom_dates: boolean = false;
    custom_trips: any;

    timeSettings: any = {
        showOnTap: true,
        showOnFocus: true,
        controls: ['time'],
        yearChange: true,
        touchUi: true,
        theme: 'mobiscroll',
        lang: 'en',
    };

    dateSettings: any = {
        showOnTap: true,
        showOnFocus: true,
        max: new Date(new Date().getFullYear() + 1, 11, 31),
        controls: ['calendar'],
        yearChange: false,
        touchUi: true,
        theme: 'mobiscroll',
        lang: 'en',
    }

    changeSettings = {
        preset: 'decimal',
        theme: 'ios',
        min: 0,
        max: 10000000,
        prefix: '$',
        animate: 'pop',
        lang: 'en',
        headerText: 'Cambio',
        display: 'bubble'
    };

    constructor(
        public api: ApiService,
        public modalController: ModalController,
        public navParams: NavParams,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public alertController: AlertController,
        public toastController: ToastController
    ) {
        this.request = this.navParams.get('request');
    }

    ngOnInit() {
        this.api.getAllDocuments('operators').then(data => {
            this.operators = data;
        })
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    async selectAircraft() {

        const modal = await this.modalController.create({
            component: AircraftsComponent,
            cssClass: 'aircrafts-modal',
            componentProps: {
                operator: this.operator
            }
        });

        modal.onDidDismiss()
            .then((data) => {
                if (data.data) {
                    this.aircraft = data.data;
                    this.getAircraftData();
                }
            });

        return await modal.present();
    }

    getAircraftData() {
        this.deals = [];
        this.api.getLastFlight(this.aircraft.tailnumber).then(data => {
            this.lastFlightInfo = data;
            console.log(this.lastFlightInfo);

            if (this.lastFlightInfo.FlightInfoExResult == undefined) {
                this.getDealFromBase();
            } else {
                this.lastFlightInfo.FlightInfoExResult.flights.forEach(element => {
                    this.lastFlightInfo.FlightInfoExResult.flights.forEach(element => {
                        element.actualdeparturetime = new Date(element.actualdeparturetime * 1000);
                        element.estimatedarrivaltime = new Date(element.estimatedarrivaltime * 1000);
                        element.filed_departuretime = new Date(element.filed_departuretime * 1000);
                        this.deals = [];

                        let first_trip = this.request.trips[0];

                        if (first_trip.departure.oaci_code != element.destination) {
                            let departure = {
                                oaci_code: element.destination,
                                city: element.destinationCity,
                                airport: element.destinationName
                            }
                            let deal = {
                                departure: departure,
                                arrival: first_trip.departure,
                                valid: true,
                            }
                            this.deals.push(deal);
                        }
                    });
                });
            };
        }, err => {
            this.getDealFromBase();
        })
    }

    changeDates() {
        this.custom_trips = JSON.parse(JSON.stringify(this.request.trips));
    }

    getDealFromBase() {
        this.deals = [];
        let first_trip = this.request.trips[0];
        if (first_trip.departure.oaci_code != this.aircraft.base.oaci_code) {
            let arrival = {
                oaci_code: this.aircraft.base.oaci_code,
                city: this.aircraft.base.city,
                airport: this.aircraft.base.airport
            }
            let deal = {
                departure: first_trip.arrival,
                arrival: arrival,
                valid: true,
            }
            this.deals.push(deal);
        }
    }

    async changeAirport(deal, type) {
        const modal = await this.modalController.create({
            component: AirportsComponent,
            componentProps: {
                'type': type
            }
        });

        modal.onDidDismiss()
            .then((data) => {
                if (data.data) {
                    deal[type] = data.data;
                }
            });

        return await modal.present();
    }

    addDeal() {
        let deal = {
            valid: true,
        }
        this.deals.push(deal)
    }

    async removeDeal(deal, i) {
        const alert = await this.alertController.create({
            header: 'Confirm',
            message: `Are you sure you want to remove the empty leg`,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Sure',
                    handler: () => {
                        this.deals.splice(i);
                    }
                }
            ]
        });

        await alert.present();

    }

    sendBid() {

        if (this.custom_dates) {
            this.custom_trips.forEach(element => {
                element.date = new Date(element.date);
                element.time = new Date(element.time);
            });
        }

        this.loader().then(loader => {
            loader.present().then(() => {
                let deals = [];
                let valid = true;
                if (this.price && this.aircraft && this.operator) {
                    this.deals.forEach(element => {
                        if (!element.time || !element.date || !element.price || !element.departure || !element.arrival) {
                            valid = false;
                            element.valid = false;
                            this.errorAlert();
                            loader.dismiss();
                        } else {
                            element = JSON.parse(JSON.stringify(element));
                            element.date = new Date(element.date);
                            let minutes = new Date(element.time).getMinutes();
                            let hours = new Date(element.time).getHours();
                            element.date = new Date(element.date).setHours(hours);
                            element.date = new Date(element.date).setMinutes(minutes);
                            element.date = new Date(element.date);
                            delete element.time;
                            delete element.valid;
                            deals.push(element);
                        }
                    });

                    if (valid) {
                        let bid = {
                            price: this.price,
                            aircraft: this.aircraft,
                            operator: this.operator,
                            request: this.request.$key,
                            account: this.request.account,
                            deals: deals,
                            preliminary: this.preliminary,
                            creation_date: new Date()
                        }
                        if (this.custom_dates) {
                            bid['custom_trips'] = this.custom_trips;
                        }

                        this.api.addDocument('bids', bid).then(data => {
                            let bid_key = data.id;
                            this.api.getAllDocuments(`accounts/${this.request.account}/push_keys`).then(response => {
                                if (response) {
                                    response.forEach(element => {
                                        let content = {
                                            title_en: 'New bid',
                                            title_es: 'Nueva oferta ',
                                            message_en: 'You got a new bid for your request',
                                            message_es: 'Tienes una nueva oferta para tu solicitud'
                                        }
                                        this.api.sendRequestPush(content, `tabs/requests/bids/${bid_key}`, element.push_key);
                                    });
                                }
                            })
                            this.toastController.create({
                                header: 'Congratulations!',
                                message: 'Your bid was sent.',
                                position: 'top',
                                buttons: [{
                                    text: 'Ok',
                                    role: 'cancel',
                                    handler: () => {
                                        // console.log('Cancel clicked');
                                    }
                                }]
                            }).then(toast => {
                                toast.present();
                            })
                            loader.dismiss();
                            this.modalController.dismiss();
                        });

                    }
                } else {
                    loader.dismiss();
                    this.errorAlert();
                }
            })
        });
    }

    async errorAlert() {
        const alert = await this.alertController.create({
            header: 'Incomplete Information',
            message: 'You must complete the deals information',
            buttons: ['OK']
        });

        await alert.present();
    }



}
