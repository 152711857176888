import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, AlertController, LoadingController, ActionSheetController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
// import { TranslateService } from '@ngx-translate/core';
// import { FunctionsService } from 'src/app/services/functions/functions.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { LoginComponent } from '../login/login.component';
// import { SignupComponent } from '../signup/signup.component';
import { HttpClient } from '@angular/common/http';
import { ChatComponent } from '../chat/chat.component';
import { Router } from '@angular/router';
// import { CallNumber } from '@ionic-native/call-number/ngx';

@Component({
    selector: 'app-charter',
    templateUrl: './charter.component.html',
    styleUrls: ['./charter.component.scss'],
})
export class CharterComponent implements OnInit {

    aircraft: any;
    trips: any;
    request_type: any = 'passengers';
    requestForm: FormGroup;
    seats: any = 1;
    pets: number = 0;

    formSettings = {
        theme: 'ios',
        themeVariant: 'light'
    };


    numpadSettings = {
        scale: 0,
        min: 1,
        max: 500,
        defaultValue: 1,
        display: 'bubble'
    };

    numpadSettingsPets = {
        scale: 0,
        min: 0,
        max: 500,
        defaultValue: 0,
        display: 'bubble'
    };

    // account actual
    account: any
    userId: any;

    constructor(
        public navParams: NavParams,
        public modalController: ModalController,
        public alertController: AlertController,
        public loadingCtrl: LoadingController,
        public api: ApiService,
        public formBuilder: FormBuilder,
        private router: Router,
        public auth: AuthService,
        // public translateService: TranslateService,
        // public functions: FunctionsService,
        private http: HttpClient,
        // private callNumber: CallNumber,
        public actionSheetController: ActionSheetController
    ) {
        this.aircraft = navParams.get('aircraft');
        this.userId = navParams.get('userId');
        this.trips = JSON.parse(JSON.stringify(navParams.get('trips')));

        this.requestForm = this.formBuilder.group({
            trips: [this.trips, [Validators.required]],
            aircraft_type: [this.aircraft, [Validators.required]],
            request_type: ['passengers', [Validators.required]],
            seats: [1, [Validators.required]],
            pets: [0, [Validators.required]],
            acceptance: [false, [Validators.required, Validators.requiredTrue]],
        });
        this.api.getDocument('accounts', this.auth.token).then(data => {
            this.account = data;
            console.log("this.account");
            console.log(this.account);
        })
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    dismiss() {
        this.modalController.dismiss();
    }

    selectType(service) {
        this.request_type = service;
        this.requestForm.controls['request_type'].setValue(this.request_type);
    }

    selectSeat(seat) {
        this.requestForm.controls['seats'].setValue(seat);
        this.seats.forEach(element => {
            element.selected = false;
        });
        seat.selected = true;
    }

    ngOnInit() { }

    async login() {
        return
    }

    async signUp() {
        return

    }

    addRequest() {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.requestForm.value.trips.forEach(element => {
                    delete element.dateSettings;
                    element.time = new Date(element.time);
                    element.date = new Date(element.date);
                });
                let request = this.requestForm.value;
                request.creation_date = new Date();
                // request.account = this.auth.token;
                request.account = this.userId
                request.number = this.generateUUID();
                request.seats = this.seats;
                request.pets = this.pets;
                request.status = 'new';
                // creando los elementos del Sistema de Notificacion
                let notifications = {
                    end_contact: false,
                    end_contact_date: '',
                    first_contact: false,
                    first_contact_date: new Date().toString(),
                    second_contact: false,
                    second_contact_date: '',
                    third_contact: false,
                    third_contact_date: '',
                }

                request.notifications = notifications
                let created_atObject = new Date()
                let day = created_atObject.getDate()
                let month = created_atObject.getMonth() + 1
                let year = created_atObject.getFullYear()

                let created_at = year + '-' + month + '-' + day
                let content = {
                    title_en: "New Request",
                    title_es: "Nueva solicitud",
                    message_es: `Solicitud #${request.number}`,
                    message_en: `Request #${request.number}`,
                }
                // datos del request
                console.log("datos del request");
                console.log(request)
                console.log(typeof (request))
                // return
                let dataTravel = ''
                // console.log(request['trips']);
                // console.log(request.trips[0]['departure']['city']);
                // console.log(request.trips[0]['arrival']['city']);
                let i = 0
                request.trips.forEach(element => {
                    console.log("---> element")
                    console.log(element)
                    console.log(element['departure']['city']);
                    console.log(element['arrival']['city']);
                    dataTravel += `&departure_${i}=${window.btoa(element['departure']['city'] + '/' + element['departure']['country'])}`
                    dataTravel += `&arrival_${i}=${window.btoa(element['arrival']['city'] + '/' + element['arrival']['country'])}`
                    i = i + 1
                });
                // console.log("dataTravel");
                // console.log(dataTravel);

                // console.log("this.auth.token")
                // console.log(this.auth.token)
                // console.log("request");
                // console.log(request);

                // Pasando por parametro los datos del usuario para crear notificacion email, solicitud recibida
                // &name=${this.account.name}
                // &lastname=${this.account.lastname}
                // &email=${this.account.email}
                // &phone=${this.account.phone}
                // &trips=${JSON.stringify(request.trips)}
                // &date_create=${created_at}
                // &name=${this.account.name}&lastname=${this.account.lastname}&email=${this.account.email}&phone=${this.account.phone}&trips=${JSON.stringify(request.trips)}&date_create=${created_at}
                //     this.http.get(`https://mails.ohbi.app/mailsNotification.php?mode=newRequestNotificationSystem`)
                //         console.log(`https://mails.ohbi.app/mailsNotification.php?mode=newRequestNotificationSystem&name=${this.account.name}&lastname=${this.account.lastname}&email=${this.account.email}&phone=${this.account.phone}&trips=${JSON.stringify(request.trips)}&date_create=${created_at}`);

                // escribiendo en la base los datos del nuevo request y enviando notificacion.

                // contruyendo la informacion para Sistema de Notificaciones
                let requestNotification = {
                    number: request.number,
                    creation_date: request.creation_date,
                    account: request.account,
                    status_active: true,
                    end_contact: true,
                    first_contact: false,
                    second_contact: true,
                    third_contact: true,
                }

                this.api.addDocument('requests-notification', requestNotification).then(data => {
                    console.log("requests-notification")
                    console.log(requestNotification);
                    this.api.addDocument('requests', request).then(data => {
                        console.log(`https://mails.ohbi.app/mailsNotification.php?mode=newRequestNotificationSystem&name=${window.btoa(this.account.name)}&lastname=${window.btoa(this.account.lastname)}&email=${window.btoa(this.account.email)}&phone=${window.btoa(this.account.phone)}&date_create=${window.btoa(created_at)}&request=${window.btoa(JSON.stringify(request))}${dataTravel}`)
                        this.http.get(`https://mails.ohbi.app/mailsNotification.php?mode=newRequestNotificationSystem&name=${window.btoa(this.account.name)}&lastname=${window.btoa(this.account.lastname)}&email=${window.btoa(this.account.email)}&phone=${window.btoa(this.account.phone)}&date_create=${window.btoa(created_at)}&request=${window.btoa(JSON.stringify(request))}${dataTravel}`)
                            .subscribe(response => {

                                loader.dismiss();
                                this.api.getAllDocuments('operators-push').then(operators_push => {
                                    operators_push.forEach(element => {
                                        this.api.sendRequestPush(content, 'tabs/requests/', element.push_key);
                                    });
                                    this.modalController.dismiss('completed');
                                    this.dismiss()
                                    this.router.navigate(['users']);

                                })
                            });
                    });
                });

                // origina en producccion
                // this.api.addDocument('requests', request).then(data => {
                //     this.http.get(`https://mailing.ohbi.app/mails.php?mode=newRequest`)
                //         .subscribe(response => {
                //             loader.dismiss();
                //             this.api.getAllDocuments('operators-push').then(operators_push => {
                //                 operators_push.forEach(element => {
                //                     this.api.sendRequestPush(content, 'tabs/requests/', element.push_key);
                //                 });
                //                 this.modalController.dismiss('completed');
                //             })
                //         });
                // });

            })
        })
    }

    public generateUUID() {
        function s4() {
            return Math.floor((1 + Math.random()) * 100000)
        }
        return s4();
    }

    async openChat() {
        const modal = await this.modalController.create({
            component: ChatComponent,
            swipeToClose: true,
            presentingElement: document.getElementById('#root-router'),
        });

        modal.onDidDismiss()
            .then((data) => {
                if (data.data) {

                }
            });
        return await modal.present();
    }

    async call() {
        return
    }

    counterButton(item, value) {
        if (value === 'add') {
            this.pets += 1
        } else if (value === 'remove') {
            this.pets -= 1
        }
        if (this.pets < 0)
            this.pets = 0
    }

    counterButtonSeats(item, value) {
        if (value === 'add') {
            this.seats += 1
        } else if (value === 'remove') {
            this.seats -= 1
        }
        if (this.seats < 1)
            this.seats = 1
    }

}
