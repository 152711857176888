import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, LoadingController } from '@ionic/angular';

@Component({
    selector: 'app-add-operator',
    templateUrl: './add-operator.component.html',
    styleUrls: ['./add-operator.component.scss'],
})
export class AddOperatorComponent implements OnInit {

    operatorForm: FormGroup;

    constructor(
        public api: ApiService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public modalController: ModalController
    ) {
        this.operatorForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            legal_name: ['', [Validators.required]],
            active: [true, [Validators.required]],
            manager: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phone: [''],
            comission: ['', [Validators.required]],
            creation_date: [new Date(), [Validators.required]],
        });
    }

    ngOnInit() {

    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    dissmiss(){
        this.modalController.dismiss();
    }

    addOperator() {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.api.addDocument('operators', this.operatorForm.value).then(data => {
                    loader.dismiss();
                    this.dissmiss();
                })
            })
        })
    }

}
