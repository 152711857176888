import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, ToastController, LoadingController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { mobiscroll, MbscSelectOptions } from '@mobiscroll/angular';

@Component({
    selector: 'app-passport',
    templateUrl: './passport.component.html',
    styleUrls: ['./passport.component.scss'],
})
export class PassportComponent implements OnInit {

    account: any;
    passportForm: FormGroup
    countries;

    passport_image: any = false;
    exist_passport: any = false;

    constructor(
        public api: ApiService,
        public auth: AuthService,
        public modalController: ModalController,
        public navParams: NavParams,
        public formBuilder: FormBuilder,
        public toastController: ToastController,
        public loadingCtrl: LoadingController
    ) {
        this.account = navParams.get('account');
        console.log(this.account);
        

        this.api.getRef('passports').ref
            .where('user', '==', this.account)
            .get()
            .then(data => {
                data.forEach(element => {
                    this.exist_passport = element.data();
                    this.exist_passport.$key = element.id;
                    console.log(this.exist_passport);
                    
                });
            })
    }

    ngOnInit() {
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    dismiss() {
        this.modalController.dismiss();
    }

}
