import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
const identifier = "token";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        public database: AngularFireDatabase,
        private angularAuth: AngularFireAuth,
        private router: Router,
        public db: AngularFirestore
    ) {
        this.setUp();
    }
    token: string = this.getTokenFromLocalStorage();

    setUp() {
        this.token = this.getTokenFromLocalStorage();
        this.angularAuth.authState.subscribe((firebaseUser) => {
            if (firebaseUser) {
                localStorage.setItem(identifier, firebaseUser.uid);
                this.token = firebaseUser.uid;
            } else {
                localStorage.removeItem(identifier)
                this.token = null;
            }
        })
    }

    getTokenFromLocalStorage(): string {
        return localStorage.getItem(identifier)
    }


    login(data) {
        console.log(data)
        return new Promise((resolve, reject) => {
            this.angularAuth.auth.signInWithEmailAndPassword(data.email, data.password).then((data) => {
                resolve(data);
            }, (err) => {
                reject(err);
            })
        });
    }

    logOut() {
        return this.angularAuth.auth.signOut().then(() => {
            this.token = null;
            this.router.navigate(['login']);
        });
    }

    resetPassword(emailAddress) {
        let auth = firebase.auth();
        return new Promise((resolve, reject) => {
            auth.sendPasswordResetEmail(emailAddress).then(function () {
                resolve(true);
            }).catch(function (error) {
                console.log(error)
                reject(error);
            });
        });
    }

    getCurrentUser() {
        return firebase.auth().currentUser;
    }

    signUp(data) {
        let self = this;
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(data.email, data.password).then(function (user_db) {

                user_db.user.updateProfile({
                    displayName: data.name,
                    photoURL: ''
                }).then(function () {

                    let account_key = user_db.user.uid;

                    self.db.collection("accounts").doc(account_key).set({
                        name: data.name,
                        lastname: data.lastname,
                        email: data.email,
                        created_at: new Date(),
                        last_login: new Date(),
                    }).then(function () {
                        resolve();
                    }).catch(function (error) {
                        console.error("Error writing document: ", error);
                        reject(error);
                    });

                }, function (error) {
                    reject(error);
                });
            }, function (error) {
                reject(error);
            });
        });
    }

    signUpUser(data, languageSingUp = 'en') {
        let self = this;
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(data.email, data.password).then(function (user_db) {

                user_db.user.updateProfile({
                    displayName: data.name,
                    photoURL: ''
                }).then(function () {

                    let account_key = user_db.user.uid;
                    let created_at = new Date()
                    self.db.collection("accounts").doc(account_key).set({
                        name: data.name,
                        country: data.country,
                        lastname: data.lastname,
                        phone: data.phone,
                        email: data.email,
                        created_at: new Date(),
                        last_login: new Date(),
                        accountType: data.accountType,
                        birthdate: data.birthdate,
                    }).then(function () {
                        console.log("exito en creacion de cuenta en base de datos")
                        resolve();
                    }).catch(function (error) {
                        console.error("Error writing document: ", error);
                        reject(error);
                    });

                }, function (error) {
                    reject(error);
                });
            }, function (error) {
                reject(error);
            });
        });
    }

}
