import { Component, OnInit, ViewChild } from '@angular/core';
import { NavParams, LoadingController, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AirportsComponent } from '../airports/airports.component';
import { IonReorderGroup, AlertController } from '@ionic/angular';


@Component({
    selector: 'app-aircraft',
    templateUrl: './aircraft.component.html',
    styleUrls: ['./aircraft.component.scss'],
})
export class AircraftComponent implements OnInit {
    @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;


    aircraftForm: FormGroup;
    images: any = [];
    aircraft_image: any = false;
    aircraft: any;
    base: any;


    constructor(
        public alertController: AlertController,
        public navParams: NavParams,
        public api: ApiService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public modalController: ModalController
    ) {
        this.aircraft = navParams.get('aircraft');
        console.log(this.aircraft.images);

        this.base = this.aircraft.base;

        this.aircraftForm = this.formBuilder.group({
            model: [this.aircraft.model, [Validators.required]],
            manufacturer: [this.aircraft.manufacturer, [Validators.required]],
            tailnumber: [this.aircraft.tailnumber, [Validators.required]],
            seats: [this.aircraft.seats, [Validators.required]],
            active: [this.aircraft.active, [Validators.required]],
            base: [this.aircraft.base, [Validators.required]],
            baggage: [this.aircraft.baggage, [Validators.required]],
            extras: [this.aircraft.extras],
            operator: [this.aircraft.operator, [Validators.required]],
            image: [false],
            // eliminar: [this.aircraft],
        });
    }

    ngOnInit() {
    }

    doReorder(ev: any) {
        this.aircraft.images = ev.detail.complete(this.aircraft.images);
        let order = 0;
        this.aircraft.images.forEach(element => {
            this.api.updateDocument(`aircrafts/${this.aircraft.$key}/images`, element.$key, {
                order: order
            });
            order += 1;
        });
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    dismiss() {
        this.modalController.dismiss();
    }

    uploadedImages(file) {
        this.images.push({
            name: file.file.name,
            src: file.src
        });
        this.aircraftForm.controls['image'].setValue(this.images);
    }

    removeImages(file) {
        let indexObject = this.searchInArray(file.file.name, this.images);
        this.images.splice(indexObject, 1);
        this.aircraftForm.controls['image'].setValue(this.images);
    }

    searchInArray(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return i;
            }
        }
    }

    async SelectBase() {

        const modal = await this.modalController.create({
            component: AirportsComponent,
            componentProps: {
                'type': 'Base'
            }
        });

        modal.onDidDismiss()
            .then((data) => {
                if (data.data) {
                    this.base = data.data;
                    this.aircraftForm.controls['base'].setValue(this.base);
                }
            });

        return await modal.present();
    }

    updateAircraft() {

        this.loader().then(loader => {
            loader.present().then(() => {
                let data = this.aircraftForm.value
                delete data.image;
                this.api.updateDocument(`aircrafts`, this.aircraft.$key, data).then(response => {
                    this.images.forEach(element => {
                        this.api.uploadImage(`aircrafts`, element.src).then(result => {
                            this.api.addDocument(`aircrafts/${this.aircraft.$key}/images/`, result);
                        })
                    });
                    loader.dismiss();
                    this.dismiss();
                })

            });
        });


    }

    async presentAlertConfirm() {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: '',
            message: '<strong>Confirmar Elimacion de Aircraft</strong>',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirmar Cancelar: ');
                    }
                }, {
                    text: 'Okay',
                    handler: () => {
                        console.log('Confirmar Eliminar');
                        // la accion del update
                        this.loader().then(loader => {
                            loader.present().then(() => {
                                let data = this.aircraftForm.value
                                console.log(data)
                                delete data.tailnumber;
                                // falta eliminar el documento de la base de datos
                                console.log(data)
                            });
                            loader.dismiss();
                            this.dismiss();
                        });
                        this.modalController.dismiss
                    }
                }
            ]
        });

        await alert.present();
    }
}
