import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { mobiscroll, MbscSelectOptions } from '@mobiscroll/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalController, NavParams, ToastController, LoadingController } from '@ionic/angular';

@Component({
    selector: 'app-visas',
    templateUrl: './visas.component.html',
    styleUrls: ['./visas.component.scss'],
})
export class VisasComponent implements OnInit {

    account: any;

    visaForm: FormGroup
    countries;
    visa_image: any = false;
    visas: any = [];

    constructor(
        public api: ApiService,
        public auth: AuthService,
        public modalController: ModalController,
        public navParams: NavParams,
        public formBuilder: FormBuilder,
        public toastController: ToastController,
        public loadingCtrl: LoadingController
    ) {
        this.account = navParams.get('account');

        this.api.getRef('visas').ref
            .where('user', '==', this.account)
            .onSnapshot(snapshots => {
                snapshots.docChanges().forEach(element => {
                    if (element.type == 'added') {
                        let visa = element.doc.data();
                        visa.$key = element.doc.id;
                        this.visas.push(visa);
                    } else if (element.type == 'modified') {
                        let visa = element.doc.data();
                        visa.$key = element.doc.id;
                        this.visas[element.oldIndex] = visa;
                    } else if (element.type == 'removed') {
                        this.visas.splice(element.oldIndex, 1);
                    }
                });
                console.log(this.visas);

            })
    }

    ngOnInit() {

    }

    dismiss() {
        this.modalController.dismiss();
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }




}
