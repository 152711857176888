import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { AuthService } from '../auth/auth.service';
import * as firebase from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TouchSequence } from 'selenium-webdriver';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        public db: AngularFirestore,
        public auth: AuthService,
        private http: HttpClient
    ) {

    }

    ///////////////////////////////
    // GENERIC CRUD API REQUESTS
    ///////////////////////////////
    getAllDocuments(collection: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection(collection)
                .get()
                .toPromise()
                .then((querySnapshot) => {
                    let arr = [];
                    querySnapshot.forEach(function (doc) {
                        var obj = JSON.parse(JSON.stringify(doc.data()));
                        obj.$key = doc.id
                        arr.push(obj);
                    });

                    if (arr.length > 0) {
                        resolve(arr);
                    } else {
                        resolve(null);
                    }


                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    getDocument(collection, documentId) {
        return new Promise((resolve, reject) => {
            this.db.collection(collection).doc(documentId)
                .get()
                .toPromise()
                .then((snapshot) => {
                    let doc = snapshot.data();
                    doc.$key = snapshot.id;
                    resolve(doc);
                }).catch((error: any) => {
                    reject(error);
                });
        })
    }

    deleteDocument(collectionName: string, docID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .delete()
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    addDocument(collectionName: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection(collectionName).add(dataObj)
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    updateDocument(collectionName: string, docID: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .update(dataObj)
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    setDocument(collectionName: string, docID: string, dataObj: any) {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .set(dataObj)
                .then((obj: any) => {
                    resolve(obj)
                })
                .catch((error: any) => {
                    reject(error);
                })
        })
    }

    getCollectionRef(collection) {
        return this.db.collection(collection, ref => ref.orderBy('seats'));
    }

    getRef(collection) {
        return this.db.collection(collection);
    }

    ///////////////////////////////
    /////////////CUSTOM API REQUESTS
    ///////////////////////////////

    uploadImage(type, image) {
        return new Promise((resolve, reject) => {

            let storageRef = firebase.storage().ref();
            const filename = this.generateUUID();
            let imageRef = storageRef.child(`${type}/${filename}.jpg`);

            imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                .then(data => {
                    let photoData = {};
                    photoData['fullPath'] = data.metadata.fullPath;
                    photoData['size'] = data.metadata.size;
                    photoData['contentType'] = data.metadata.contentType;
                    photoData['md5Hash'] = data.metadata.md5Hash;
                    photoData['bucket'] = data.metadata.bucket;
                    photoData['updated'] = data.metadata.updated;
                    imageRef.getDownloadURL().then(data => {
                        photoData['downloadURL'] = data;
                        resolve(photoData);
                    });
                })
        })
    }

    getLastFlight(iden) {
        return new Promise((resolve, reject) => {
            this.http.get(`https://api.ohbi.app/flights.php?ident=${iden}&mode=last_flight`)
                .subscribe((response) => {
                    console.log(response);
                    // let data = JSON.parse(response['_body']);
                    resolve(response);
                }, err => {
                    reject(err);
                });
        });
    }

    getHistory(ident) {
        return new Promise((resolve, reject) => {
            this.http.get(`https://api.ohbi.app/flights.php?ident=${ident}&mode=history`)
                .subscribe((response) => {
                    console.log(response);
                    // let data = JSON.parse(response['_body']);
                    resolve(response);
                }, err => {
                    reject(err);
                });
        });
    }

    private generateUUID(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    confirmRequestPayment(request, reservation) {
        return new Promise((resolve, reject) => {


            let batch = this.db.firestore.batch();

            batch.update(this.db.firestore.collection(`reservations`).doc(reservation.$key), {
                "status": 'paid'
            });

            batch.update(this.db.firestore.collection(`requests`).doc(request.$key), {
                "status": 'paid'
            });

            batch.update(this.db.firestore.collection(`bids`).doc(reservation.bid), {
                "status": 'paid'
            });

            request.bid.deals.forEach(deal => {
                let deal_key = this.db.createId();
                batch.set(this.db.firestore.collection(`deals`).doc(deal_key), {
                    "departure": deal.departure,
                    "arrival": deal.arrival,
                    "price": deal.price,
                    "date": deal.date,
                    "operator": reservation.operator,
                    "creation_date": new Date(),
                    "number": this.generateNumber(),
                    "aircraft": request.bid.aircraft,
                    "aircraft_type": request.aircraft_type,
                });
            });

            batch.commit().then(data => {
                // Correo electronico para aceptacion de bid, se envia a usuario y a operador
                // Debe de contener que la oderta # tal y tal fue aceptada por la bid tal y tal
                // y recordarle que tiene 24 hroas para hacer el pago
                resolve(true);
            });
        })

    }

    public generateNumber() {
        function s4() {
            return Math.floor((1 + Math.random()) * 100000)
        }
        return s4();
    }

    sendRequestPush(content, route, push_key) {

        const post_data = {
            'app_id': '9810259b-35de-4470-b4a4-79666000f30f',
            'headings': {
                'en': content.title_en,
                'es': content.title_es,
            },
            'contents': {
                'en': content.message_en,
                'es': content.message_es,
            },
            "include_player_ids": [push_key],
            "data": {
                "route": route
            },
        }

        this.http.post('https://onesignal.com/api/v1/notifications', post_data)
            .subscribe(new_data => {
                console.log(new_data)
            }, error => {
                console.log(error);
            });
    }

    sendPush(content, push_keys) {

        return new Promise((resolve, reject) => {
            const post_data = {
                'app_id': '9810259b-35de-4470-b4a4-79666000f30f',
                'headings': {
                    'en': content.title_en,
                    'es': content.title_es,
                },
                'contents': {
                    'en': content.message_en,
                    'es': content.message_es,
                },
                "include_player_ids": push_keys,
            }

            this.http.post('https://onesignal.com/api/v1/notifications', post_data)
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
    }

    pushToSegment(content, route, segment) {

        return new Promise(async (resolve, reject) => {
            let respFirst = await this.pushToSegmentFirstOneSignal(content, route, segment)
            // console.log("respFirst = ", respFirst)
            let respSecond = await this.pushToSegmentSecondOneSignal(content, route, segment)
            // console.log("respSecond = ", respSecond)
            resolve('sucess')
        });
    }

    pushToSegmentFirstOneSignal(content, route, segment) {
        return new Promise((resolve, reject) => {
            const post_data = {
                'app_id': '9810259b-35de-4470-b4a4-79666000f30f',
                'headings': {
                    'en': content.title_en,
                    'es': content.title_es,
                },
                'contents': {
                    'en': content.message_en,
                    'es': content.message_es,
                },
                "included_segments": [segment],
                "data": {
                    "route": route
                },
            }

            let headers = new HttpHeaders({
                'Authorization': `Basic ZmZiNzk2ZDUtM2JkMC00YWUzLWE0ZGQtZmFjNWI5ZDIwZWFl`
            });

            this.http.post('https://onesignal.com/api/v1/notifications', post_data, { headers: headers })
                .subscribe(new_data => {
                    resolve(new_data);
                }, error => {
                    resolve(error);
                });
        });
    }

    // nueva cuenta OneSignal PremierTec
    pushToSegmentSecondOneSignal(content, route, segment) {
        return new Promise((resolve, reject) => {
            const post_data = {
                'app_id': 'd970b3e6-ac89-4629-82ae-b8a3977910b6',
                'headings': {
                    'en': content.title_en,
                    'es': content.title_es,
                },
                'contents': {
                    'en': content.message_en,
                    'es': content.message_es,
                },
                "included_segments": [segment],
                "data": {
                    "route": route
                },
            }

            let headers = new HttpHeaders({
                'Authorization': `Basic ZmI2NTcxMjItNTBlOS00ZTM3LTlkOWMtYzIwOTc4MTZkMzAz`
            });

            this.http.post('https://onesignal.com/api/v1/notifications', post_data, { headers: headers })
                .subscribe(new_data => {
                    resolve(new_data);
                }, error => {
                    resolve(error);
                });
        });
    }

    addOperatorBackup(key, data) {

        let batch = this.db.firestore.batch();

        batch.set(this.db.firestore.collection(`operators`).doc(key),
            data
        );

        batch.commit().then(data => {
            console.log('termino');
        });
    }

    addAircraftsBackup(data) {
        let batch = this.db.firestore.batch();

        data.forEach(element => {
            let key = element.key
            let data = element.data;
            let images = element.images;

            batch.set(this.db.firestore.collection(`aircrafts`).doc(key),
                data
            );

            images.forEach(element => {
                batch.set(this.db.firestore.collection(`aircrafts/${key}/images`).doc(element.$key),
                    element
                );
            });
        });

        batch.commit().then(data => {
            console.log('termino');
        });
    }

    addRequestsBackup(data) {
        let batch = this.db.firestore.batch();

        data.forEach(element => {
            let key = element.key
            let data = element.data;

            data.trips.forEach(element => {
                element.date = new Date(element.date);
                element.time = new Date(element.time);
                console.log(element.date);

            });
            if (data.number == undefined) {
                data.number = this.generateUUID2();
            }
            console.log(data);

            batch.set(this.db.firestore.collection(`requests`).doc(key),
                data
            );
        });
        console.log(data);


        batch.commit().then(data => {
            console.log('termino');
        });

    }

    public generateUUID2() {
        function s4() {
            return Math.floor((1 + Math.random()) * 100000)
        }
        return s4();
    }

    addBidsBackup(data) {
        let batch = this.db.firestore.batch();
        console.log(data);

        data.forEach(element => {
            console.log(element);

            let key = element.key
            let data = element.data;
            batch.set(this.db.firestore.collection(`bids`).doc(key),
                data
            );
        });
        console.log(batch);

        batch.commit().then(data => {
            console.log('termino');
        }, err => {
            console.log(err);
        });
    }



}
