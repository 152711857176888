import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
    selector: 'app-add-new',
    templateUrl: './add-new.component.html',
    styleUrls: ['./add-new.component.scss'],
})
export class AddNewComponent implements OnInit {

    NewsForm: FormGroup;

    constructor(
        public api: ApiService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public modalController: ModalController
    ) {
        this.NewsForm = this.formBuilder.group({
            title_en: ['', [Validators.required]],
            title_es: ['', [Validators.required]],
            content_en: ['', [Validators.required]],
            content_es: ['', [Validators.required]],
            image: ['', [Validators.required]],
            site_url: ['']
        });
    }

    ngOnInit() { }

    addNew() {
        this.api.addDocument('news', this.NewsForm.value).then(data => {
            this.modalController.dismiss();
        })

    }

}
