import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { countries } from "../../../assets/countries";
import { MbscSelectOptions } from '@mobiscroll/angular';

@Component({
	selector: 'app-add-user',
	templateUrl: './add-user.component.html',
	styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
	singUpForm: FormGroup;
	language: any = 'en';
	countries = countries;
	settings: MbscSelectOptions = {
        filter: true,
        lang: 'en',
    };
	languageSingUp: any = 'en';

	constructor(
		public formBuilder: FormBuilder,
		private auth: AuthService,
		public alertCtrl: AlertController,
		private router: Router,
		public loadingCtrl: LoadingController,
		public http: HttpClient,
		public modalController: ModalController,
	) {
		countries.forEach(element => {
			element['value'] = element['code']
			element['text'] = element['name']
		});
		this.singUpForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			lastname: ['', [Validators.required]],
			email: ['', [Validators.required]],
			phone: [''],
			country: ['', [Validators.required]],
			password: ['', [Validators.required]],
			accountType: [false],
			birthdate: [''],
			acceptance: [false, [Validators.required, Validators.requiredTrue]],
		});
	}

	ngOnInit() { }

	async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

	dismiss() {
        this.modalController.dismiss();
    }

	signUp() {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.auth.signUpUser(this.singUpForm.value).then(data => {
                    loader.dismiss();
                    let body = new FormData();
                    let fullname = this.singUpForm.value.name + this.singUpForm.value.lastname;

                    body.append('name', fullname);
                    body.append('email', this.singUpForm.value.email);
                    this.dismiss();

                }, err => {
                    let alert = this.alertCtrl.create({
                        header: 'Error',
                        message: ' ' + err + ' ',
                        buttons: ['OK']
                    }).then(alert => alert.present());
                    loader.dismiss();
                });
            });
        });
    }

    onAutoFormatChanged(ev) {
        this.setFirstLetterToUppercase(ev.detail.value);
    }

    private setFirstLetterToUppercase(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

}
