import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import 'firebase/storage'
import { AirportsComponent } from '../airports/airports.component';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-add-aircraft',
    templateUrl: './add-aircraft.component.html',
    styleUrls: ['./add-aircraft.component.scss'],
})
export class AddAircraftComponent implements OnInit {

    aircraftForm: FormGroup;
    images: any = [];
    aircraft_image: any = false;
    operator: any;
    base: any;

    constructor(
        public navParams: NavParams,
        public api: ApiService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public modalController: ModalController
    ) {
        this.operator = navParams.get('operator');
        this.aircraftForm = this.formBuilder.group({
            model: ['', [Validators.required]],
            manufacturer: ['', [Validators.required]],
            tailnumber: ['', [Validators.required]],
            seats: ['', [Validators.required]],
            active: [true, [Validators.required]],
            base: ['', [Validators.required]],
            baggage: ['', [Validators.required]],
            extras: [''],
            creation_date: [new Date(), [Validators.required]],
            operator: [this.operator.$key, [Validators.required]],
            image: ['', [Validators.required]],
        });
    }

    ngOnInit() {

    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    dismiss() {
        this.modalController.dismiss();
    }

    uploadedImages(file) {
        this.images.push({
            name: file.file.name,
            src: file.src
        });
        this.aircraftForm.controls['image'].setValue(this.images);
    }

    removeImages(file) {
        let indexObject = this.searchInArray(file.file.name, this.images);
        this.images.splice(indexObject, 1);
        this.aircraftForm.controls['image'].setValue(this.images);
    }

    searchInArray(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].name === nameKey) {
                return i;
            }
        }
    }

    async SelectBase() {

        const modal = await this.modalController.create({
            component: AirportsComponent,
            componentProps: {
                'type': 'Base'
            }
        });

        modal.onDidDismiss()
            .then((data) => {
                if (data.data) {
                    this.base = data.data;
                    this.aircraftForm.controls['base'].setValue(this.base);
                }
            });

        return await modal.present();
    }


    addAircraft() {
        this.loader().then(loader => {
            loader.present().then(() => {
                let data = this.aircraftForm.value;
                delete data.image;
                this.api.addDocument(`aircrafts`, data).then(response => {
                    let aircraftId = response.id
                    this.images.forEach(element => {
                        this.api.uploadImage(`aircrafts`, element.src).then(result => {
                            this.api.addDocument(`aircrafts/${aircraftId}/images/`, result);
                        })
                    });
                    Swal.fire('¡Listo!', 'Tu Equipo a sido creado', 'success');
                    loader.dismiss();
                    this.dismiss();
                });
            });
        });
    }



}
