import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController, NavParams, LoadingController } from '@ionic/angular';
import { FormBuilder } from '@angular/forms'

import algoliasearch from 'algoliasearch';
import { algoliaConfig } from 'src/environments/environment'

const client = algoliasearch(algoliaConfig.appId, algoliaConfig.apiKey);
const index = client.initIndex('aircrafts');

@Component({
    selector: 'app-aircrafts',
    templateUrl: './aircrafts.component.html',
    styleUrls: ['./aircrafts.component.scss'],
})
export class AircraftsComponent implements OnInit {

    aircrafts: any = [];
    operator: any;
    empty: any = true;
    loading: any = false;

    constructor(
        public api: ApiService,
        public modalController: ModalController,
        public navParams: NavParams,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController
    ) {
        this.operator = this.navParams.get('operator');
        this.getAircrafts();
    }

    ngOnInit() { }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    selectAircraft(aircraft) {
        this.modalController.dismiss(aircraft);
    }

    getAircrafts() {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.api.getRef('aircrafts').ref
                    .where('operator', '==', this.operator)
                    .where("active", "==", true)
                    .get()
                    .then(response => {
                        response.forEach(element => {
                            let aircraft = element.data();
                            aircraft.$key = element.id;
                            this.api.getAllDocuments(`aircrafts/${aircraft.$key}/images`).then(images => {
                                images.sort(function (a, b) {
                                    var orderA = a.order, orderB = b.order;
                                    return orderA - orderB;
                                });
                                aircraft.images = images;
                            });
                            this.aircrafts.unshift(aircraft);
                        });
                        loader.dismiss();
                    })
            })
        })
    }

    setSearch(e) {
        this.loading = false;
        let query = e.detail.value;

        if (query == '') {
            this.aircrafts = [];
            this.empty = true;
            this.loading = false;
            this.getAircrafts();
        } else {
            this.empty = false;
            this.aircrafts = [];
            index.search(query)
                .then(({ hits }) => {
                    hits.forEach(element => {
                        let aircraft = element;
                        if (aircraft['operator'] == this.operator) {
                            aircraft['images'] = [];
                            aircraft['$key'] = element.objectID;
                            this.api.getAllDocuments(`aircrafts/${aircraft['$key']}/images`).then(images => {
                                images.sort(function (a, b) {
                                    var orderA = a.order, orderB = b.order;
                                    return orderA - orderB;
                                });
                                aircraft['images'] = images;
                            });
                            this.aircrafts.push(element);
                        }
                    });
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

}
