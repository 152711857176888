import { Component, OnInit } from '@angular/core';
import { ToastController, AlertController, LoadingController, NavParams, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { AircraftsComponent } from '../aircrafts/aircrafts.component';
import { AirportsComponent } from '../airports/airports.component';

@Component({
    selector: 'app-add-deal',
    templateUrl: './add-deal.component.html',
    styleUrls: ['./add-deal.component.scss'],
})
export class AddDealComponent implements OnInit {

    timeSettings: any = {
        showOnTap: true,
        showOnFocus: true,
        controls: ['time'],
        yearChange: true,
        touchUi: true,
        theme: 'mobiscroll',
        lang: 'en',
    };

    dateSettings: any = {
        showOnTap: true,
        showOnFocus: true,
        max: new Date(new Date().getFullYear() + 1, 11, 31),
        controls: ['calendar'],
        yearChange: false,
        touchUi: true,
        theme: 'mobiscroll',
        lang: 'en',
    }

    changeSettings = {
        preset: 'decimal',
        theme: 'ios',
        min: 0,
        max: 10000000,
        prefix: '$',
        animate: 'pop',
        lang: 'en',
        headerText: 'Cambio',
        display: 'bubble'
    };

    operator: any;
    aircraft: any;
    arrival: any;
    departure: any;
    date: any;
    time: any;
    operators: any;
    price: any;
    aircraft_type: any;
    dealForm: FormGroup;

    constructor(
        public api: ApiService,
        public modalController: ModalController,
        public navParams: NavParams,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public alertController: AlertController,
        public toastController: ToastController
    ) {
        this.dealForm = this.formBuilder.group({
            operator: ['', [Validators.required]],
            aircraft: ['', [Validators.required]],
            arrival: ['', [Validators.required]],
            departure: ['', [Validators.required]],
            date: ['', [Validators.required]],
            time: ['', [Validators.required]],
            price: [''],
            seats: [0, [Validators.required]],
            min_seats: [0, [Validators.required]],
            aircraft_type: ['', [Validators.required]],
            creation_date: [new Date(), [Validators.required]],
            number: [this.api.generateNumber(), [Validators.required]],
        });
    }

    ngOnInit() {
        this.api.getAllDocuments('operators').then(data => {
            this.operators = data;
        })
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    async selectAircraft() {

        const modal = await this.modalController.create({
            component: AircraftsComponent,
            cssClass: 'aircrafts-modal',
            componentProps: {
                operator: this.operator
            }
        });

        modal.onDidDismiss()
            .then((data) => {
                if (data.data) {
                    this.aircraft = data.data;
                    this.dealForm.controls['aircraft'].setValue(this.aircraft);
                    this.dealForm.controls['seats'].setValue(this.aircraft.seats);
                }
            });

        return await modal.present();
    }

    selectedOperator() {
        this.operator = this.dealForm.value.operator;
    }

    async changeAirport(type) {

        const modal = await this.modalController.create({
            component: AirportsComponent,
            componentProps: {
                'type': type
            }
        });

        modal.onDidDismiss()
            .then((data) => {
                if (data.data) {
                    if (type == 'arrival') {
                        this.arrival = data.data;
                        this.dealForm.controls['arrival'].setValue(data.data);
                    } else {
                        this.departure = data.data;
                        this.dealForm.controls['departure'].setValue(data.data);
                    }

                }
            });

        return await modal.present();
    }

    addDeal() {
        let data = this.dealForm.value;
        data.seats = Number(data.seats);
        data.min_seats = Number(data.min_seats);
        data.date = new Date(data.date);
        let minutes = new Date(data.time).getMinutes();
        let hours = new Date(data.time).getHours();
        data.date = new Date(data.date).setHours(hours);
        data.date = new Date(data.date).setMinutes(minutes);
        data.date = new Date(data.date);
        delete data.time;
        delete data.valid;

        this.api.addDocument('deals', data).then(reponse => {
            let content = {
                title_en: 'New Deal Available ️️️✈️',
                title_es: 'Nuevo Deal Disponible ✈️',
                message_en: `Deal available to fly from ${data.departure.city} to ${data.arrival.city}`,
                message_es: `Deal disponible para volar desde ${data.departure.city} hacia ${data.arrival.city}`,
            }
            this.api.pushToSegment(content, `tabs/deals/${reponse.id}`, 'All').then(data => {
                this.modalController.dismiss();
            })
        });
    }



}
