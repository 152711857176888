import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-radar',
    templateUrl: './radar.component.html',
    styleUrls: ['./radar.component.scss'],
})
export class RadarComponent implements OnInit {

    searched: any = false;
    loading: any = false;
    searchInput: any;
    flights: any;

    constructor(
        public api: ApiService,
        public modalController: ModalController
    ) {

    }

    ngOnInit() {

    }

    setSearch(e) {
        let query = e.detail.value;
        if (query == '') {
            this.searched = false;
            this.loading = false;
        } else {
        }
    }

    searchNow() {
        this.api.getHistory(this.searchInput).then(data => {
            if (data['FlightInfoExResult'] == undefined) {
                this.loading = false;
            } else {
                this.flights = data['FlightInfoExResult']['flights'];
                this.flights.forEach(element => {
                    element.filed_departuretime = new Date(element.filed_departuretime * 1000);
                    element.estimatedarrivaltime = new Date(element.estimatedarrivaltime * 1000);

                })
            }
        })
    }

    selectFlight(flight) {
        this.modalController.dismiss(flight.faFlightID);
    }


}
