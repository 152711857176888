import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-add-user-operator',
    templateUrl: './add-user-operator.component.html',
    styleUrls: ['./add-user-operator.component.scss'],
})
export class AddUserOperatorComponent implements OnInit {

    userForm: FormGroup;
    operator: any;

    constructor(
        public api: ApiService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public modalController: ModalController,
        public navParams: NavParams,
    ) {
        this.operator = this.navParams.get('operator');
        
        this.userForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            lastname: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            operator: [this.operator.$key, [Validators.required]],
            creation_date: [new Date(), [Validators.required]],
        });
    }

    ngOnInit() { }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    dissmiss() {
        this.modalController.dismiss();
    }

    addUser() {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.api.addDocument('user-operators', this.userForm.value).then(data => {
                    loader.dismiss();
                    this.dissmiss();
                })
            })
        })
    }

}
