import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController, NavParams, AlertController, LoadingController } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-bids',
    templateUrl: './bids.component.html',
    styleUrls: ['./bids.component.scss'],
})
export class BidsComponent implements OnInit {

    request: any;

    changeSettings = {
        preset: 'decimal',
        theme: 'ios',
        min: 0,
        max: 10000000,
        prefix: '$',
        animate: 'pop',
        lang: 'en',
        headerText: 'Cambio',
        display: 'bubble'
    };

    constructor(
        public api: ApiService,
        public modalController: ModalController,
        public navParams: NavParams,
        public formBuilder: FormBuilder,
        public alertController: AlertController,
        public loadingCtrl: LoadingController
    ) {
        this.request = this.navParams.get('request');
        console.log(this.request.bids);

    }

    ngOnInit() { }

    async setPrice(bid) {
        const alert = await this.alertController.create({
            header: 'CONFIRM!',
            subHeader: 'Please confirm',
            message: 'Are you sure you want confirm this price as the final price?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Okay',
                    handler: () => {
                        this.api.updateDocument('bids', bid.$key, {
                            price: bid.price,
                            preliminary: false
                        }).then(data => {
                            bid.preliminary = false;
                        })
                    }
                }
            ]
        });

        await alert.present();
    }

}
