import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController, NavParams, AlertController, LoadingController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PassengerComponent } from '../passenger/passenger.component';
import { ChatComponent } from '../chat/chat.component';

@Component({
    selector: 'app-request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss'],
})
export class RequestComponent implements OnInit {

    request: any;
    requestForm: FormGroup;
    user: any;
    reservation: any;
    payment_proofs: any = [];

    changeSettings = {
        preset: 'decimal',
        theme: 'ios',
        min: 0,
        max: 10000000,
        prefix: '$',
        animate: 'pop',
        lang: 'en',
        headerText: 'Cambio',
        display: 'bubble'
    };

    bid: any;

    constructor(
        public api: ApiService,
        public modalController: ModalController,
        public navParams: NavParams,
        public formBuilder: FormBuilder,
        public alertController: AlertController,
        public loadingCtrl: LoadingController
    ) {
        this.request = this.navParams.get('request');

        this.api.getDocument('accounts', this.request.account).then(data => {
            let user = data;
            user['created_at'] = user['created_at'].toDate();
            this.user = user;
        })

        if (status !== 'new') {


            this.api.getRef('reservations').ref
                .where('request', '==', this.request.$key)
                .onSnapshot(snapsthos => {
                    snapsthos.docChanges().forEach(element => {

                        this.reservation = element.doc.data();
                        this.reservation.passengers = [];
                        this.reservation.total_passengers = 0;
                        this.reservation.$key = element.doc.id;

                        this.api.getRef(`reservation-passengers/`).ref
                            .where('reservation', '==', this.reservation.$key)
                            .onSnapshot(snapshots => {
                                this.reservation.total_passengers += snapshots.size;
                                snapshots.docChanges().forEach(element => {
                                    if (element.type == 'added') {
                                        let passenger = element.doc.data();
                                        passenger.$key = element.doc.id;
                                        this.reservation.passengers.push(passenger)
                                    } else if (element.type == 'modified') {
                                        let passenger = element.doc.data();
                                        passenger.$key = element.doc.id;
                                        this.reservation.passengers[element.oldIndex] = passenger;
                                    } else if (element.type == 'removed') {
                                        this.reservation.passengers.splice(element.oldIndex, 1);
                                    }
                                });
                            })

                        if (this.reservation.user_is_passenger) {
                            this.api.getDocument('accounts', this.reservation.account).then(data => {
                                data['passenger'] = this.reservation.account;
                                this.reservation.total_passengers += 1;
                                this.reservation.passengers.push(data);
                            })
                        }

                        this.api.getRef('payment_proofs').ref
                            .where('reservation', '==', this.reservation.$key)
                            .onSnapshot(snapshots => {
                                snapshots.docChanges().forEach(element => {
                                    if (element.type == 'added') {
                                        let proof = element.doc.data();
                                        proof.$key = element.doc.id;
                                        this.payment_proofs.push(proof);
                                    } else if (element.type == 'modified') {
                                        let proof = element.doc.data();
                                        proof.$key = element.doc.id;
                                        this.payment_proofs[element.oldIndex] = proof;
                                    } else if (element.type == 'removed') {
                                        this.payment_proofs.splice[element.oldIndex, 1];
                                    }
                                });
                            })


                    });

                })
        }
    }

    ngOnInit() {

    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    async acceptPayment() {
        const alert = await this.alertController.create({
            header: 'CONFIRM!',
            subHeader: 'Please confirm',
            message: 'Are you sure you want to accept this payment?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Okay',
                    handler: () => {
                        this.loader().then(loader => {
                            loader.present().then(() => {
                                this.api.confirmRequestPayment(this.request, this.reservation).then(data => {
                                    loader.dismiss();
                                })
                            })
                        })
                    }
                }
            ]
        });

        await alert.present();
    }

    async selectPasenger(passenger) {
        const modal = await this.modalController.create({
            component: PassengerComponent,
            componentProps: {
                'passenger': passenger
            }
        });
        modal.present();
    }

    async acceptProof(proof) {
        const alert = await this.alertController.create({
            header: 'CONFIRM!',
            subHeader: 'Please confirm',
            message: 'Are you sure you want to accept this payment?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Okay',
                    handler: () => {
                        this.loader().then(loader => {
                            loader.present().then(() => {
                                this.api.updateDocument('payment_proofs', proof.$key, {
                                    'status': 'paid',
                                    'amount': proof.amount,
                                }).then(data => {
                                    loader.dismiss();
                                }, err => {
                                    loader.dismiss();
                                })
                            })
                        })
                    }
                }
            ]
        });
        await alert.present();
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: 'Error',
            message: 'Please make sure you set an amount for this payment.',
            buttons: ['OK']
        });

        await alert.present();
    }

    async selectChat() {
        this.user.key = this.user.$key;
        this.user.username = {}
        this.user.username.name = this.user.name;
        this.user.username.lastname = this.user.lastname;

        const modal = await this.modalController.create({
            component: ChatComponent,
            cssClass: 'add-modal',
            componentProps: {
                conversation: this.user
            }
        });
        return await modal.present();
    }

}
