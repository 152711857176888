import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController, NavParams, AlertController, LoadingController } from '@ionic/angular';
import { ChatComponent } from '../chat/chat.component';

@Component({
    selector: 'app-deal',
    templateUrl: './deal.component.html',
    styleUrls: ['./deal.component.scss'],
})
export class DealComponent implements OnInit {

    deal: any;
    paid: any;
    total: any = 0;

    changeSettings = {
        preset: 'decimal',
        theme: 'ios',
        min: 0,
        max: 10000000,
        prefix: '$',
        animate: 'pop',
        lang: 'en',
        headerText: 'Cambio',
        display: 'bubble'
    };

    constructor(
        public api: ApiService,
        public modalController: ModalController,
        public navParams: NavParams,
        public formBuilder: FormBuilder,
        public alertController: AlertController,
        public loadingCtrl: LoadingController
    ) {
        this.deal = this.navParams.get('deal');


        this.deal.reservations.forEach(reservation => {
            
            if (!this.deal.opened) {
                reservation.creation_date = reservation.creation_date.toDate();
            }

            reservation.proofs = [];
            reservation.passengers = [];

            this.api.getRef(`reservation-passengers/`).ref
                .where('reservation', '==', reservation.$key)
                .onSnapshot(snapshots => {
                    reservation.total_passengers += snapshots.size;
                    snapshots.docChanges().forEach(element => {
                        if (element.type == 'added') {
                            let passenger = element.doc.data();
                            passenger.$key = element.doc.id;
                            reservation.passengers.push(passenger)
                        } else if (element.type == 'modified') {
                            let passenger = element.doc.data();
                            passenger.$key = element.doc.id;
                            reservation.passengers[element.oldIndex] = passenger;
                        } else if (element.type == 'removed') {
                            reservation.passengers.splice(element.oldIndex, 1);
                        }
                    });
                })

            this.api.getDocument('accounts', reservation.account).then(data => {
                reservation.account_info = data;
                if (reservation.user_is_passenger) {
                    data['passenger'] = reservation.account;
                    reservation.total_passengers += 1;
                    reservation.passengers.push(data);
                }
            })


            this.api.getRef('payment_proofs').ref
                .where('reservation', '==', reservation.$key)
                .onSnapshot(snapshots => {

                    snapshots.docChanges().forEach(element => {
                        if (element.type == 'added') {
                            let proof = element.doc.data();
                            proof.$key = element.doc.id;
                            if (proof.amount) {
                                this.total += proof.amount;
                            }
                            reservation.proofs.push(proof)
                        } else if (element.type == 'modified') {
                            let proof = element.doc.data();
                            proof.$key = element.doc.id;
                            if (proof.amount) {
                                this.total += proof.amount;
                            }
                            reservation.proofs[element.oldIndex] = proof;
                        } else if (element.type == 'removed') {
                            reservation.proof.splice(element.oldIndex, 1);
                        }
                    });
                })
        });
        this.deal.opened = true;
        console.log(this.deal);
        

    }

    ngOnInit() {
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'bubbles',
            animated: true,
            mode: 'ios',
            translucent: true,
            cssClass: 'custom-class custom-loading',
            // message: 'Please wait...',
        });
    }

    async acceptProof(proof) {
        const alert = await this.alertController.create({
            header: 'CONFIRM!',
            subHeader: 'Please confirm',
            message: 'Are you sure you want to accept this payment?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'Okay',
                    handler: () => {
                        this.loader().then(loader => {
                            loader.present().then(() => {
                                this.api.updateDocument('payment_proofs', proof.$key, {
                                    'status': 'paid',
                                    'amount': proof.amount,
                                }).then(data => {
                                    loader.dismiss();
                                }, err => {
                                    loader.dismiss();
                                })
                            })
                        })
                    }
                }
            ]
        });
        await alert.present();
    }


    async selectChat(user) {

        user.key = user.$key;
        user.username = {};
        user.username.name = user.name;
        user.username.lastname = user.lastname;

        const modal = await this.modalController.create({
            component: ChatComponent,
            cssClass: 'add-modal',
            componentProps: {
                conversation: user
            }
        });
        return await modal.present();
    }

}
