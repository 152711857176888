// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const firebaseConfig = {
  apiKey: "AIzaSyBuiaybubHc6OTrXSf27Ktk2JemO35TJ6o",
  authDomain: "ohbi-app.firebaseapp.com",
  databaseURL: "https://ohbi-app.firebaseio.com",
  projectId: "ohbi-app",
  storageBucket: "ohbi-app.appspot.com",
  messagingSenderId: "111631654567"
}

export const algoliaConfig = {
  appId: '8J72PIRO41',
  apiKey: 'c0769bd3948ad3783aae9bf9631496ed'
}
// antiguas credenciales
// export const algoliaConfig = {
//   appId: 'XHUFGHLL69',
//   apiKey: 'c5c8f3e8e5fbc00aeea56a26e8c88235'
// }
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
