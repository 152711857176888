import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth/auth.service';
import { ApiService } from './services/api/api.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private auth: AuthService,
        private api: ApiService,
        private router: Router,
        private http: HttpClient,
    ) {
        this.initializeApp();
    }

    initializeApp() {
        let now_date = new Date();
        let first_date = new Date();
        let second_date = new Date();
        second_date.setDate(first_date.getDate() + 1)
        let third_date = new Date();
        third_date.setDate(second_date.getDate() + 1)
        // sumar 24 horas a fecha actual
        console.log("now_date");
        console.log(now_date);
        console.log("first_date");
        console.log(first_date);
        console.log("second_date");
        console.log(second_date);
        console.log("third_date");
        console.log(third_date);

        this.api.getRef('requests-notification').ref
            .where('status_active', '==', true)
            // .where('status_active', '==', true)
            .get()
            .then(snapshots => {
                // console.log("---> snapshots");
                // console.log(snapshots);
                // console.log("snapshots.size");
                // console.log(snapshots.size);

                // recorriendo lista de request Pendientes de notificacion
                snapshots.docs.forEach(element => {
                    // trabajando la creation_date / fist / second / third Message
                    // console.log("------------------->>>>>>> requestItem");

                    let requestItem = element.data();
                    requestItem.$key = element.id;
                    // console.log(requestItem);
                    // console.log(requestItem.creation_date.toDate());

                    // calculando Fechas Validas para notificaciones
                    let first_date = new Date(requestItem.creation_date.toDate())
                    let second_date = new Date();
                    second_date.setDate(first_date.getDate() + 1)
                    let third_date = new Date();
                    third_date.setDate(second_date.getDate() + 1)
                    // console.log("calculate_first_date");
                    // console.log(typeof(first_date));
                    // console.log(first_date);
                    // console.log("calculate_second_date");
                    // console.log(typeof(second_date));
                    // console.log(second_date);
                    // console.log("calculate_third_date");
                    // console.log(typeof(third_date));
                    // console.log(third_date);
                    console.log("requestItem.first_contact");
                    console.log(requestItem.first_contact);

                    // evaluando las fechas si aplica la notificacion24Horas
                    if (second_date > now_date && requestItem.second_contact == true) {
                        // console.log("second_date es mayor que fecha actual");
                        this.api.getRef('requests').ref
                            .where("number", "==", 175857)
                            // .where('status_active', '==', true)
                            .get()
                            .then(snapshots => {
                                // console.log("snapshots.size");
                                // console.log(snapshots.size);
                                snapshots.docs.forEach(element => {
                                    // trabajando la creation_date / fist / second / third Message
                                    // console.log("------------------->>>>>>> requestItem");
                                    let request = element.data();
                                    // console.log(typeof(request));
                                    // console.log(request);
                                    // console.log(request['account']);

                                    this.http.get(`https://mails.ohbi.app/mailsNotification.php?mode=newNotificationSystem24&name=${request.account}&lastname=${request.account}`)
                                        .subscribe((response) => {
                                            // console.log(response);
                                            if (response == 1) {
                                                requestItem.second_contact = false
                                                this.api.updateDocument(`requests-notification`, requestItem.$key, requestItem).then(response => {
                                                    console.log("updateDocument(`requests-notification`)")
                                                })
                                            }
                                        });
                                })
                            })
                    } else {
                        console.log("false");
                        console.log("no hay notificacion de 24 horas");
                    }

                    // evaluando las fechas si aplica la notificacion48Horas
                    if (third_date > now_date && requestItem.third_contact == true) {
                        // console.log("second_date es mayor que fecha actual");
                        this.api.getRef('requests').ref
                            .where("number", "==", 175857)
                            // .where('status_active', '==', true)
                            .get()
                            .then(snapshots => {
                                // console.log("snapshots.size");
                                // console.log(snapshots.size);
                                snapshots.docs.forEach(element => {
                                    // trabajando la creation_date / fist / second / third Message
                                    // console.log("------------------->>>>>>> requestItem");
                                    let request = element.data();
                                    // console.log(typeof(request));
                                    // console.log(request);
                                    // console.log(request['account']);

                                    this.http.get(`https://mails.ohbi.app/mailsNotification.php?mode=newNotificationSystem48&name=${request.account}&lastname=${request.account}`)
                                        .subscribe((response) => {
                                            // console.log(response);
                                            if (response == 1) {
                                                requestItem.third_contact = false
                                                this.api.updateDocument(`requests-notification`, requestItem.$key, requestItem).then(response => {
                                                    console.log("updateDocument(`requests-notification`)")
                                                })
                                            }
                                        });
                                })
                            })
                    } else {
                        console.log("false");
                        console.log("no hay notificacion de 48 horas");
                    }
                    // finalizando sistema de notificacion email ya se lanzaron las 3 notificaciones
                    if (third_date > now_date && requestItem.end_contact == true) {
                        // console.log("second_date es mayor que fecha actual");
                        this.api.getRef('requests').ref
                            .where("number", "==", 175857)
                            // .where('status_active', '==', true)
                            .get()
                            .then(snapshots => {
                                // console.log("snapshots.size");
                                // console.log(snapshots.size);
                                snapshots.docs.forEach(element => {
                                    // trabajando la creation_date / fist / second / third Message
                                    // console.log("------------------->>>>>>> requestItem");
                                    let request = element.data();
                                    // console.log(typeof(request));
                                    // console.log(request);
                                    // console.log(request['account']);

                                    this.http.get(`https://mails.ohbi.app/mailsNotification.php?mode=newNotificationSystemEnd&name=${request.account}&lastname=${request.account}`)
                                        .subscribe((response) => {
                                            // console.log(response);
                                            if (response == 1) {
                                                requestItem.status_active = false
                                                requestItem.end_contact = false
                                                this.api.updateDocument(`requests-notification`, requestItem.$key, requestItem).then(response => {
                                                    console.log("updateDocument(`requests-notification`)")
                                                })
                                            }
                                        });
                                })
                            })
                    } else {
                        console.log("false");
                        console.log("no hay notificacion a Cerrar Sistema de Notificacion");
                    }

                });
            })

        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }
}
